<template>
  <lf-modal
    :title="
      $t('ORGANIZATION.EMAIL_TEMPLATES.SEND_EMAIL', {
        address: $t('COMMON.TO').toLowerCase()
      })
    "
    :close="() => emit('close')"
  >
    <template #content>
      <div class="p-6 space-y-6">
        <select-contacts
          :contacts="testData?.contacts"
          v-model="selectedData.recipient_user_ids"
        />
        <select-email-branding
          :brandings="testData?.email_customizations"
          v-model="selectedData.email_customization_id"
        />
      </div>
      <modal-buttons
        class="bg-white border-t border-gray-200 justify-end py-5"
        :on-cancel="() => emit('close')"
        :on-save="() => emit('email:send', selectedData)"
        :save-button-text="$t('COMMON.SEND')"
        :disabled="!selectedData.recipient_user_ids.length"
      />
    </template>
  </lf-modal>
</template>
<script setup lang="ts">
import LfModal from "@/components/ui/Modal.vue";
import ModalButtons from "@/components/ui/ModalButtons.vue";
import SelectContacts from "@/views/deals/components/emails/SelectContacts.vue";
import SelectEmailBranding from "@/views/deals/components/emails/SelectEmailBranding.vue";
import { ref, type PropType } from "vue";
import type { SendEmailModalData, TestEmailData } from "@/models/emails";

defineProps({
  testData: {
    type: Object as PropType<TestEmailData | null>,
    required: true
  }
});

const emit = defineEmits<{
  close: [];
  "email:send": [SendEmailModalData];
}>();

const selectedData = ref<SendEmailModalData>({
  recipient_user_ids: [],
  email_customization_id: null
});
</script>
